'use client';

import React, { EventHandler, ReactNode, MouseEvent } from 'react';
import { IconBooks, IconUsersGroup, IconStars, IconHeadphones } from '@tabler/icons-react';
import Button from '@dr-pam/common-components/Components/Form/Button';
import Link from 'next/link';
import { usePathname } from 'next/navigation';

export type SubscriptionBenefitsProps = {
	className?: string;
	onExploreClicked?: () => void;
	exploreButtonClassName?: string;
	isRenewal?: boolean;
	children?: ReactNode;
	showLoginLink?: boolean;
	onLoginClicked?: EventHandler<MouseEvent<HTMLAnchorElement>>;
};

export default function SubscriptionBenefits(props: SubscriptionBenefitsProps) {
	const { className, onExploreClicked, exploreButtonClassName, isRenewal, children, onLoginClicked } = props;
	const showLoginLink = props.showLoginLink ?? true;

	const pathname = usePathname();

	const handleExploreClicked = () => {
		onExploreClicked?.();
	};

	return (
		<div className={`SubscriptionBenefits ${className ?? ''}`}>
			<p>
				<strong>When you {isRenewal ? 'renew' : 'sign up'}, you&apos;ll get access to:</strong>
			</p>
			<ul>
				<li>
					<IconBooks size={48} strokeWidth={1} />
					<span>
						The entire library of articles (including videos) covering every aspect of newborn, baby and
						toddler sleep.
					</span>
				</li>
				<li>
					<IconUsersGroup size={48} strokeWidth={1} />
					<span>
						Direct access to Dr Pamela Douglas, creator of The Possums Programs, through unlimited online
						group education sessions.
					</span>
				</li>
				<li>
					<IconStars size={48} strokeWidth={1} />
					<span>
						Extra programs like Caring for You (mental health), When Baby Comes Home (before birth),
						breastfeeding support*, little people + food, and more.
					</span>
				</li>
				<li>
					<IconHeadphones size={48} strokeWidth={1} />
					<span>Audio versions of all the essential programs.</span>
				</li>
			</ul>
			{children}
			{onExploreClicked && (
				<div className="explore">
					<Button className={exploreButtonClassName} onClick={handleExploreClicked}>
						Explore sign-up options
					</Button>
					{showLoginLink && (
						<Link
							className="subtle login"
							href={`/login?r=${encodeURI(pathname)}`}
							onClick={onLoginClicked}
						>
							Already signed up? Login here.
						</Link>
					)}
				</div>
			)}
			<p className="footnote">
				*Complementary access to the Gestalt method of fit and hold will be available with registration to The
				Possums Sleep Program until the launch of Breastfeeding Stripped Bare in early 2025
			</p>
		</div>
	);
}
