'use client';
import React, { ReactNode, forwardRef, useImperativeHandle, useRef, useState } from 'react';
import { UseFormReturnType, isNotEmpty, useForm } from '@mantine/form';
import FormControl from '../Form/FormControl';
import TextInput from '../Form/TextInput';
import Button from '../Form/Button';
import Form from '../Form/Form';
import useLoadTracker from '../../Hooks/useLoadTracker';

export type LoginFormProps = {
	className?: string;
	onSubmit?: (values: LoginFormType) => Promise<boolean>;
	children?: ReactNode;
	email?: string;
};

export default forwardRef<UseFormReturnType<LoginFormType>, LoginFormProps>(function LoginForm(
	props: LoginFormProps,
	ref,
) {
	const { className, onSubmit, children, email } = props;

	const { isLoading, addLoader, removeLoader } = useLoadTracker();
	const [error, setError] = useState<ReactNode | null>(null);

	const form = useForm<LoginFormType>({
		initialValues: {
			email: email ?? '',
			password: '',
		},
		validate: {
			email: isNotEmpty('Email is required'),
			password: isNotEmpty('Password is required'),
		},
	});

	useImperativeHandle(ref, () => form, [form]);

	const passwordRef = useRef<HTMLInputElement>(null);

	const onFormSubmit = async (values: LoginFormType) => {
		if (!onSubmit) {
			return;
		}

		const loader = addLoader();
		try {
			setError(null);
			const result = await onSubmit(values);
			if (!result) {
				form.setFieldValue('password', '');
				setTimeout(() => {
					passwordRef.current?.focus();
				}, 100);
				removeLoader(loader);
			}
			// If we get here, the login was successful.
			// DON'T remove the loader, because we're navigating away and it will be removed by the page change.
		} catch (err) {
			debugger;
			if (err instanceof Error) {
				setError(err.message);
			} else if (err) {
				setError(`${err}`);
			} else {
				setError('Unknown error');
			}
			removeLoader(loader);
		}
	};

	return (
		<div className={`LoginForm ${className ?? ''}`}>
			<Form form={form} onSubmit={onFormSubmit} disabled={isLoading}>
				<FormControl name="email" label="Email">
					<TextInput type="email" placeholder="username or email address" autoComplete="email" />
				</FormControl>
				<FormControl name="password" label="Password">
					<TextInput
						ref={passwordRef}
						type="password"
						placeholder="password"
						autoComplete="current-password"
					/>
				</FormControl>
				<Button type="submit" isLoading={isLoading}>
					Login
				</Button>
				{error && <div className="error">{error}</div>}
				{children}
			</Form>
		</div>
	);
});

export type LoginFormType = {
	email: string;
	password: string;
};
