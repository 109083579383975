'use client';

import React, { useEffect, MouseEvent } from 'react';
import type { ProductSubscriptionType } from '@dr-pam/common-types/database';
import SubscriptionBenefits from '../subscription/SubscriptionBenefits';
import { showPurchaseSubscriptionModal } from '../subscription/PurchaseSubscriptionModal';
import { useModalProvider } from '@dr-pam/common-components/Components/Modal/ModalProvider';
import { useSearchParams } from 'next/navigation';

export type SignupViaSubscriptionProps = {
	className?: string;
	subscriptionTypes: ProductSubscriptionType[];
};

export default function SignupViaSubscription(props: SignupViaSubscriptionProps) {
	const { className, subscriptionTypes } = props;
	const searchParams = useSearchParams();
	const automaticallyOpenModal = searchParams.get('sign-up') !== null;

	const modalProvider = useModalProvider();

	const handleExploreClicked = () => {
		showPurchaseSubscriptionModal(modalProvider, {
			modalId: 'subscription-options',
			subscriptionTypes,
		});
	};

	const handleLoginClicked = (event: MouseEvent<HTMLAnchorElement>) => {
		event.preventDefault();
		const el: HTMLAnchorElement = event.target as HTMLAnchorElement;
		const loginEl = el.closest('.login-and-subs-container')?.querySelector('.Login') as HTMLDivElement | undefined;
		if (loginEl) {
			loginEl.scrollIntoView({ behavior: 'smooth', block: 'center' });
		}
	};

	useEffect(() => {
		if (automaticallyOpenModal) {
			handleExploreClicked();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [automaticallyOpenModal]);

	return (
		<div className={`SignupViaSubscription ${className ?? ''}`}>
			<h4 className="login-title">Sign up</h4>
			<SubscriptionBenefits
				onExploreClicked={handleExploreClicked}
				exploreButtonClassName="secondary outline"
				onLoginClicked={handleLoginClicked}
			/>
		</div>
	);
}
