'use client';
import { useAuthService } from '@dr-pam/common-components/Components/Auth/AuthProvider';
import LoginForm, { LoginFormType } from '@dr-pam/common-components/Components/Auth/LoginForm';
import useAuthenticatedUser from '@dr-pam/common-components/Hooks/useAuthenticatedUser';
import NotificationUtils from '@dr-pam/common-components/Utils/NotificationUtils';
import Link from 'next/link';
import { useSearchParams } from 'next/navigation';
import React from 'react';

export type LoginProps = {
	className?: string;
};

export default function Login(props: LoginProps) {
	const { className } = props;

	const searchParams = useSearchParams();
	const authService = useAuthService();
	const { user } = useAuthenticatedUser();

	const onLoginRequested = async (form: LoginFormType): Promise<boolean> => {
		try {
			const loggedInUser = await authService.signIn(form.email, form.password);
			if (!loggedInUser) {
				NotificationUtils.showError(
					new Error('Please check your credentials and try again.'),
					'Incorrect email and/or password.',
				);
				return false;
			}

			setTimeout(() => {
				// Use a full page navigation here rather than a router push so that
				// it forces the page to reload and clear any cached data.
				const redirectUrl = searchParams.get('r') ?? '/';
				window.location.href = redirectUrl;
			}, 1000); // Small delay to allow the user to see the success message

			NotificationUtils.showSuccess('Redirecting you now...', 'Login successful', 3000);

			return true;
		} catch (error) {
			NotificationUtils.showError(error as Error, 'Login failed');
			return false;
		}
	};

	return (
		<div className={`Login ${className ?? ''}`}>
			<h4 className="login-title">Login</h4>
			<div className="login-container">
				{user ? (
					<div>
						<p>You are already logged in.</p>
						<Link href="/account" className="button outline secondary">
							View my account
						</Link>
					</div>
				) : (
					<LoginForm onSubmit={onLoginRequested}>
						<Link className="forgot" href="/forgot-password">
							Forgot password
						</Link>
					</LoginForm>
				)}
			</div>
		</div>
	);
}
